//import Mock from 'mockjs'
import { SUCCESS_CODE } from '@/constants'
//import { toAnyString } from '@/utils'

const timeout = 1000
//const count = 100

interface DictionaryIF {
  name: string
  code: string
  module: string
  remark?: string
  created_at?: string
  updated_at?: string
}
let dictionaryList: DictionaryIF[] = []

dictionaryList.push(
  ...[
    {
      name: '产品可供应状态',
      code: 'productAvailableStatus',
      module: 'product',
      remark: '',
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00'
    },
    {
      name: '产品业务类别',
      code: 'productBusinessType',
      module: 'product',
      remark: '',
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00'
    },
    {
      name: '腔体型号',
      code: 'cavityModel',
      module: 'product',
      remark: '',
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00'
    },
    {
      name: '设备厂商',
      code: 'deviceManufacturer',
      module: 'product',
      remark: '',
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00'
    },
    {
      name: '制造工艺',
      code: 'manufactureCraft',
      module: 'product',
      remark: '',
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00'
    },
    {
      name: '产品制程',
      code: 'manufactureProcess',
      module: 'product',
      remark: '',
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00'
    },
    {
      name: '料号状态',
      code: 'materialNumberStatus',
      module: 'product',
      remark: '',
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00'
    },
    {
      name: '晶圆尺寸',
      code: 'waferSize',
      module: 'product',
      remark: '',
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00'
    },
    {
      name: '所属行业',
      code: 'companyIndustry',
      module: 'company',
      remark: '',
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00'
    },
    {
      name: '企业性质',
      code: 'companyProperty',
      module: 'company',
      remark: '',
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00'
    },
    {
      name: '企业规模',
      code: 'companySize',
      module: 'company',
      remark: '',
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00'
    },
    {
      name: '公司业务类型',
      code: 'companyBusinessType',
      module: 'company',
      remark: '',
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00'
    }
  ]
)

interface DictionaryItemIF {
  id?: number
  name: string
  dictionary_code: string
  status: number
  sort: number
  created_at?: string
  updated_at?: string
}
let dictionaryItemList: DictionaryItemIF[] = []
dictionaryItemList.push(
  ...[
    {
      id: 1,
      name: '陶瓷制造',
      dictionary_code: 'productBusinessType',
      status: 1,
      sort: 0,
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00'
    },
    {
      id: 2,
      name: '精密再生',
      dictionary_code: 'productBusinessType',
      status: 1,
      sort: 0,
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00'
    },
    {
      id: 2,
      name: '综合服务',
      dictionary_code: 'productBusinessType',
      status: 1,
      sort: 0,
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00'
    },
    {
      id: 4,
      name: '综合服务2',
      dictionary_code: 'productBusinessType',
      status: 0,
      sort: 0,
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00'
    },
    {
      id: 5,
      name: '综合服务3',
      dictionary_code: 'productBusinessType',
      status: 0,
      sort: 0,
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00'
    }
  ]
)

export default [
  // 数据字典列表接口
  {
    url: '/mock/dictionary/list',
    method: 'get',
    timeout,
    response: ({ query }) => {
      const { name, pageIndex, pageSize } = query
      const mockList = dictionaryList.filter((item) => {
        if (name && item.name.indexOf(name) < 0) return false
        return true
      })
      const pageList = mockList.filter(
        (_, index) => index < pageSize * pageIndex && index >= pageSize * (pageIndex - 1)
      )
      return {
        code: SUCCESS_CODE,
        data: {
          total: mockList.length,
          list: pageList
        }
      }
    }
  },
  // 数据项列表接口
  {
    url: '/mock/dictionaryitem/list',
    method: 'get',
    timeout,
    response: ({ query }) => {
      const { code, pageIndex, pageSize } = query
      const mockList = dictionaryItemList.filter((item) => {
        if (item.dictionary_code == code) return true
        return false
      })
      const pageList = mockList.filter(
        (_, index) => index < pageSize * pageIndex && index >= pageSize * (pageIndex - 1)
      )
      return {
        code: SUCCESS_CODE,
        data: {
          total: mockList.length,
          list: pageList
        }
      }
    }
  }
]
